import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { Auth } from 'aws-amplify'
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  { path: '/', name: 'Home', component: Home, redirect: '/albums' },
  { path: '/signin', name: 'signin', component: () => import('../views/auth/SignIn.vue') },
  {
    path: '/user',
    component: () => import('../views/auth/UserProfile.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: 'password', name: 'changePassword', component: () => import('../views/auth/ChangePassword.vue'), meta: { requiresAuth: true } },
      { path: 'profile', name: 'userEditProfile', component: () => import('../views/auth/UpdateProfile.vue'), meta: { requiresAuth: true } }
    ]
  },
  {
    path: '/albums',
    component: () => import('../views/GenericRouterView.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'albumList', component: () => import('../views/albums/AlbumList.vue') },
      { path: ':slug', name: 'viewAlbum', component: () => import('../views/albums/ViewAlbum.vue'), props: true }
    ]
  },
  {
    path: '/photos',
    component: () => import('../views/GenericRouterView.vue'),
    // meta: { requiresAuth: true },
    children: [
      { path: '/albums/:albumSlug/photos/:photoId', name: 'viewPhoto', component: () => import('../views/photos/ViewPhoto.vue'), props: true },
    ]
  },
  {
    path: '/collections',
    component: () => import('../views/GenericRouterView.vue'),
    children: [
      { path: '', name: 'collectionList', component: () => import('../views/collections/CollectionList.vue'), meta: { requiresAuth: true } },
      { path: ':slug', name: 'viewCollection', component: () => import('../views/collections/ViewCollection.vue'), props: true },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
});

const onAuthRequired = async (from: Route, to: Route, next: any) => {
  if (from.matched.some(record => record.meta.requiresAuth)) {
    // If user is not auth'd, will throw an exception
    try {
      await Auth.currentAuthenticatedUser()
      next()
    } catch {
      next({ name: 'signin' })
    }
  } else {
    next()
  }
}

router.beforeEach(onAuthRequired)

export default router;
