export default class Photo {
  public id = '';

  public album = '';

  public fileName = '';
  public title = '';
  public description = '';

  public photoDate = '';
  public uploadDate = '';

  public sizes: object = { };

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
