<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <div class="container">
      <b-navbar-brand to="/">Photos</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <template v-if="isAuthenticated">
            <b-nav-item to="/albums">Albums</b-nav-item>
            <b-nav-item to="/collections">Collections</b-nav-item>

            <!-- <b-nav-item-dropdown>
              <template #button-content>
                Inventory
              </template>
              <b-dropdown-item to="/inventory" exact>Items</b-dropdown-item>
              <b-dropdown-item to="/inventory/categories">Categories</b-dropdown-item>
            </b-nav-item-dropdown> -->
          </template>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-on:click="login" v-if="!isAuthenticated">Sign in</b-nav-item>
          <b-nav-item-dropdown right v-if="isAuthenticated">
            <!-- Using 'button-content' slot -->
            <template slot="button-content">{{ userName }}</template>
            <b-dropdown-item to="/user/profile">Profile</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { Auth } from 'aws-amplify'
import authHelpers from '../authHelpers';

export default {
  data () {
    return {
      isAuthenticated: false,
      userName: ''
    }
  },
  computed: {
  },
  async created () {
    await this.authenticate()
  },
  watch: {
    '$route': 'authenticate'
  },
  methods: {
    async authenticate () {
      this.isAuthenticated = await authHelpers.isAuthenticated();
      this.userName = await authHelpers.getUserName();
    },
    login () {
      this.$router.push({ name: 'signin' })
    },
    async logout () {
      await Auth.signOut()
      await this.authenticate()

      // Navigate back to home
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
