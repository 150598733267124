import Collection from '@/models/Collection';
import api from './api';

const collectionService = {
  async getCollections(): Promise<Collection[]> {
    const response = await api.get('collections');

    const collections = response.data.map((x: any) => new Collection(x));
    return collections;
  },

  async getCollection(slug: string): Promise<Collection> {
    const response = await api.get(`collections/${slug}`);

    const collection = new Collection(response.data);
    return collection;
  },

  async create(collection: Collection): Promise<Collection> {
    const response = await api.post('collections', collection);

    collection = new Collection(response.data);
    return collection;
  },

  async update(collection: Collection): Promise<Collection> {
    const response = await api.put(`collections/${collection.slug}`, collection);

    collection = new Collection(response.data);
    return collection;
  },

}

export default collectionService;
