export default class {
    public slug = '';

    public name = '';

    public coverPhotoId = '';
    public coverPhotoFilename = '';
    public coverPhotoUrl = '';

    public createdOn = '';
    public updatedOn = '';

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
