import Photo from '@/models/Photo';
import api from './api';

const photoService = {
  async getPhotos(): Promise<Photo[]> {
    // TODO: ADD pagination
    const response = await api.get('photos');

    const photos = response.data.map((x: any) => new Photo(x));
    return photos;
  },

  async getPhotosForAlbum(slug: string): Promise<Photo[]> {
    const response = await api.get(`albums/${slug}/photos`);

    const photos = response.data.map((x: any) => new Photo(x));
    return photos;
  },

  async getPhotosForCollection(slug: string): Promise<Photo[]> {
    const response = await api.get(`collections/${slug}/photos`);

    const photos = response.data.map((x: any) => new Photo(x));
    return photos;
  },

  async getPhoto(id: string): Promise<Photo> {
    const response = await api.get(`photos/${id}`);

    const photo = new Photo(response.data);
    return photo;
  }
};

export default photoService;
