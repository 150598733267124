export default class Collection {
  public slug = '';

  public name = '';
  public description = '';

  public hidden = false;

  // public createdOn = '';
  // public updatedOn = '';

  public photoIds: number[] = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
