import Album from '@/models/Album';
import api from './api';

const albumService = {
  async getAlbums(): Promise<Album[]> {
    const response = await api.get('albums');

    const albums = response.data.map((x: any) => new Album(x));
    return albums;
  },

  async getAlbum(slug: string): Promise<Album> {
    const response = await api.get(`albums/${slug}`);

    const album = new Album(response.data);
    return album;
  },

  async updateAlbum(album: Album): Promise<Album> {
    const response = await api.put(`albums/${album.slug}`, album);
    return new Album(response.data);
  },

  async setCoverPhoto(slug: string, photoId: string): Promise<Album> {
    const data = {
      photoId: photoId
    };

    const response = await api.put(`albums/${slug}/cover`, data);
    return new Album(response.data);
  },
}

export default albumService;
