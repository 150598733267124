import { getField, updateField } from 'vuex-map-fields';
import Album from '@/models/Album';
import AlbumService from '@/services/albumService';

interface AlbumState {
  albums: Album[],
  currentAlbum: Album | null,
  showPropertyEditor: boolean,
  showCoverSelector: boolean,
}

const state: AlbumState = {
  albums: [],
  currentAlbum: null,
  showPropertyEditor: false,
  showCoverSelector: false,
};

const getters = {
  getField,
  currentAlbum: (state: AlbumState) => state.currentAlbum,
  showPropertyEditor: (state: AlbumState) => state.showPropertyEditor,
  showCoverSelector: (state: AlbumState) => state.showCoverSelector,
};

const mutations = {
  updateField,

  SET_ALBUMS(state: AlbumState, value: Album[]) {
    state.albums = value;
  },

  SET_CURRENT_ALBUM(state: AlbumState, value: Album | null) {
    state.currentAlbum = value;
  },

  SET_SHOW_PROPERTY_EDITOR(state: AlbumState, value: boolean) {
    state.showPropertyEditor = value;
  },

  SET_SHOW_COVER_SELECTOR(state: AlbumState, value: boolean) {
    state.showCoverSelector = value;
  },
};

const actions = {
  async loadAlbums({ commit }) {
    const albums = await AlbumService.getAlbums();
    commit('SET_ALBUMS', albums);
  },

  async loadAlbum({ commit }, slug: string) {
    const album = await AlbumService.getAlbum(slug);
    commit('SET_CURRENT_ALBUM', album);
  },

  openPropertyEditor({ commit }) {
    commit('SET_SHOW_PROPERTY_EDITOR', true);
  },

  async updateAlbum({ commit, state }) {
    await AlbumService.updateAlbum(state.currentAlbum);

    commit('SET_SHOW_PROPERTY_EDITOR', false);
  },

  openCoverSelector({ commit }) {
    commit('SET_SHOW_COVER_SELECTOR', true);
  },

  async setCoverPhoto({ commit, state }, data) {
    await AlbumService.setCoverPhoto(state.currentAlbum.slug, data.photoId);

    commit('SET_SHOW_COVER_SELECTOR', false);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
