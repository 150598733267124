import { getField, updateField } from 'vuex-map-fields';
import Photo from '@/models/Photo';
import PhotoService from '@/services/photoService';

interface PhotoState {
  allPhotos: Photo[];
  albumPhotos: Photo[];
  currentPhoto: Photo | null;
  selectedPhotoIds: string[];
};

const state: PhotoState = {
  allPhotos: [],
  albumPhotos: [],
  currentPhoto: null,
  selectedPhotoIds: [],
};

const getters = {
  getField,

  allPhotos: (state: PhotoState) => state.allPhotos,

  albumPhotos: (state: PhotoState) => state.albumPhotos,
  currentPhoto: (state: PhotoState) => state.currentPhoto,

  selectedPhotoIds: (state: PhotoState) => state.selectedPhotoIds,
  hasSelectedPhotos: (state: PhotoState) => state.selectedPhotoIds.length > 0,
  isPhotoSelected: (state: PhotoState) => (photo: Photo) => {
    return state.selectedPhotoIds.includes(photo.id);
  },

  currentPhotoIndex: (state: PhotoState) => {
    if (!state.currentPhoto || state.albumPhotos.length < 1) {
      return -1;
    }

    return state.albumPhotos.findIndex(x => x.id === state.currentPhoto?.id);
  },

  previousPhoto: (state: PhotoState, getters: any) => {
    const prevIndex = getters.currentPhotoIndex === 0
      ? state.albumPhotos.length - 1
      : getters.currentPhotoIndex - 1;
    const previous = state.albumPhotos[prevIndex];
    return previous;
  },

  nextPhoto: (state: PhotoState, getters: any) => {
    const nextIndex = getters.currentPhotoIndex === state.albumPhotos.length - 1
      ? 0
      : getters.currentPhotoIndex + 1;
    const next = state.albumPhotos[nextIndex];
    return next;
  },
};

const mutations = {
  updateField,

  SET_ALL_PHOTOS(state: PhotoState, value: Photo[]) {
    state.allPhotos = value;
  },

  SET_ALBUM_PHOTOS(state: PhotoState, value: Photo[]) {
    state.albumPhotos = value;
  },

  SET_CURRENT_PHOTO(state: PhotoState, value: Photo) {
    state.currentPhoto = value;
  },

  SET_SELECTED_PHOTOS(state: PhotoState, value: string[]) {
    state.selectedPhotoIds = value;
  },
};

const actions = {
  async loadAllPhotos({ commit }) {
    // commit('INCREASE_API_CALLS', null, { root: true });

    const photos = await PhotoService.getPhotos();
    commit('SET_ALL_PHOTOS', photos);

    // commit('DECREASE_API_CALLS', null, { root: true });
  },

  async loadPhotosForAlbum({ commit }, albumSlug: string) {
    // commit('INCREASE_API_CALLS', null, { root: true });

    const photos = await PhotoService.getPhotosForAlbum(albumSlug);
    commit('SET_ALBUM_PHOTOS', photos);

    // commit('DECREASE_API_CALLS', null, { root: true });
  },

  async loadPhoto({ commit }, id: string) {
    // commit('INCREASE_API_CALLS', null, { root: true });

    const photo = await PhotoService.getPhoto(id);
    commit('SET_CURRENT_PHOTO', photo);

    // commit('DECREASE_API_CALLS', null, { root: true });
  },

  togglePhotoSelected({ commit, state }: { commit: any, state: PhotoState }, photo: Photo) {
    let selectedIds = state.selectedPhotoIds;

    if (!state.selectedPhotoIds.includes(photo.id)) {
      selectedIds = [ ...selectedIds, photo.id ];
    } else {
      selectedIds = selectedIds.filter(x => x !== photo.id);
    }

    commit('SET_SELECTED_PHOTOS', selectedIds);
  },

  clearSelection({ commit }) {
    commit('SET_SELECTED_PHOTOS', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
