import { getField, updateField } from 'vuex-map-fields';
import Collection from '@/models/Collection';
import collectionService from '@/services/collectionService';
import Photo from '@/models/Photo';
import photoService from '@/services/photoService';

const getDefaultCollectionState = () => {
  return new Collection();
};

interface CollectionState {
  collections: Collection[];
  currentCollection: Collection | null;
  collectionPhotos: Photo[];
  showPropertyEditor: boolean;
  showPhotoSelector: boolean;

  showAddToCollection: boolean;
};

const state: CollectionState = {
  collections: [],
  currentCollection: getDefaultCollectionState(),
  collectionPhotos: [],
  showPropertyEditor: false,
  showPhotoSelector: false,
  showAddToCollection: false,
};

const getters = {
  getField,

  collections: (state: CollectionState) => state.collections,
  currentCollection: (state: CollectionState) => state.currentCollection,
  collectionPhotos: (state: CollectionState) => state.collectionPhotos,
  showPropertyEditor: (state: CollectionState) => state.showPropertyEditor,
};

const mutations = {
  updateField,

  SET_COLLECTIONS(state: CollectionState, value: Collection[]) {
    state.collections = value;
  },

  SET_CURRENT_COLLECTION(state: CollectionState, value: Collection | null) {
    state.currentCollection = value;
  },

  SET_COLLECTION_PHOTOS(state: CollectionState, value: Photo[]) {
    state.collectionPhotos = value;
  },

  SET_SHOW_PROPERTY_EDITOR(state: CollectionState, value: boolean) {
    state.showPropertyEditor = value;
  },

  SET_SHOW_PHOTO_SELECTOR(state: CollectionState, value: boolean) {
    state.showPhotoSelector = value;
  },

  SET_COLLECTION_SELECTED_PHOTOS(state: CollectionState, value: number[]) {
    state.currentCollection = new Collection({
      ...state.currentCollection,
      photoIds: value
    });
  },

  SET_SHOW_ADD_TO_COLLECTION(state: CollectionState, value: boolean) {
    state.showAddToCollection = value;
  },
};

const actions = {
  async loadCollections({ commit }) {
    const collections = await collectionService.getCollections();
    commit('SET_COLLECTIONS', collections);
  },

  async loadCollection({ commit }, slug: string) {
    const collection = await collectionService.getCollection(slug);
    commit('SET_CURRENT_COLLECTION', collection);
  },

  async loadCollectionPhotos({ commit }, slug: string) {
    const photos = await photoService.getPhotosForCollection(slug);
    commit('SET_COLLECTION_PHOTOS', photos);
  },

  addCollection({ commit }) {
    commit('SET_CURRENT_COLLECTION', getDefaultCollectionState());
    commit('SET_SHOW_PROPERTY_EDITOR', true);
  },

  async saveCollection({ commit, state, dispatch }) {
    if (!state.currentCollection.slug) {
      // New collection
      await collectionService.create(state.currentCollection);
      dispatch('loadCollections');
    }

    commit('SET_CURRENT_COLLECTION', null);
    commit('SET_SHOW_PROPERTY_EDITOR', false);
  },

  async openPhotoSelector({ commit, dispatch }) {
    await dispatch('photos/loadAllPhotos', {}, { root: true });
    commit('SET_SHOW_PHOTO_SELECTOR', true);
  },

  async updateCollectionPhotos({ commit, state, dispatch }, photoIds: number[]) {
    commit('SET_COLLECTION_SELECTED_PHOTOS', photoIds);
    await collectionService.update(state.currentCollection);

    await dispatch('loadCollectionPhotos', state.currentCollection.slug);
    commit('SET_SHOW_PHOTO_SELECTOR', false);
  },

  addToCollection({ commit }) {
    commit('SET_SHOW_ADD_TO_COLLECTION', true);
  },

  async addToCollectionSubmit({ commit, dispatch, state, rootGetters }, collectionSlug: string) {
    const collection = state.collections.find(x => x.slug === collectionSlug);
    if (!collection) {
      return;
    }

    const selectedIds = rootGetters['photos/selectedPhotoIds'];
    collection.photoIds = [...collection.photoIds, ...selectedIds ];

    await collectionService.update(collection);

    commit('SET_SHOW_ADD_TO_COLLECTION', false);
    dispatch('photos/clearSelection', null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
