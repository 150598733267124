<template>
  <div id="app">
    <main-nav />
    <router-view />

      <!-- <alerts /> -->

      <!-- TODO: Slot for title -->
  </div>
</template>

<script>
import MainNav from './components/MainNav'

export default {
  components: {
    MainNav
  },
  metaInfo: {
    titleTemplate: '%s - Photos'
  }
}
</script>
