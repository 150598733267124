import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue'
import VueMeta from 'vue-meta';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Auth } from 'aws-amplify'
import authConfig from './AuthConfig'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faEdit, faImage, faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// TODO: Replace with SCSS version
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

Auth.configure(authConfig)

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(VueMeta);

library.add(faCheck, faEdit, faImage, faImages);

Vue.component('fa-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// Clear the console on HMR reload
// https://stackoverflow.com/a/53867503/976042
window.addEventListener('message', (e) => {
  if (e.data && typeof e.data === 'string' && e.data.match(/webpackHotUpdate/)) {
    console.log('hot reload happened')
    console.clear()
  }
});
