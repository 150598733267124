import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import store from '../store';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

instance.interceptors.request.use(async config => {
  store.commit('INCREASE_API_CALLS', null, { root: true });

  let accessToken = '';

  try {
      accessToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
  } catch {
      // Could not retrieve access token; request will not be auth'd
  }

  return config;
},
error => {
  store.commit('DECREASE_API_CALLS', null, { root: true });
  Promise.reject(error);
});

instance.interceptors.response.use((response: AxiosResponse) => {
  store.commit('DECREASE_API_CALLS', null, { root: true });

  return response;
}, _ => {
  store.commit('DECREASE_API_CALLS', null, { root: true });
});

const Api = {
    get(resource: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return instance.get(resource, config);
    },

    post(resource: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return instance.post(resource, data, config);
    },

    put(resource: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return instance.put(resource, data, config);
    },

    delete(resource: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return instance.delete(resource, config);
    }
};

export default Api;
