import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import auth from './modules/auth';
import albums from './modules/albums';
import photos from './modules/photos';
import collections from './modules/collections';

Vue.use(Vuex);

const logger = createLogger({
  collapsed: true,
  logActions: true,
  logMutations: false
});

export default new Vuex.Store({
  state: {
    isLoading: false,
    apiCallsInProgress: 0,
  },
  getters: {
    apiCallsInProgress: (state) => state.apiCallsInProgress,
    showLoadingIndicator: (state) => state.apiCallsInProgress > 0,
  },
  mutations: {
    INCREASE_API_CALLS(state) {
      state.apiCallsInProgress = state.apiCallsInProgress + 1;
    },

    DECREASE_API_CALLS(state) {
      state.apiCallsInProgress = state.apiCallsInProgress - 1;
    }
  },
  actions: {

  },
  modules: {
    auth,
    albums,
    photos,
    collections,
  },
  plugins: [
    logger
  ]
});
